/*
 * Welcome to your app's main JavaScript file!
 *
 * We recommend including the built version of this JavaScript file
 * (and its CSS file) in your base layout (base.html.twig).
 */


import './styles/app.css';
import 'aos/dist/aos.css';
import './script/asb.js';
import 'bootstrap';
var AOS = require('aos');
AOS.init();
import './script/recaptcha.js';

jQuery.event.special.touchstart = {
    setup: function( _, ns, handle ) {
        this.addEventListener("touchstart", handle, { passive: !ns.includes("noPreventDefault") });
    }
};
jQuery.event.special.touchmove = {
    setup: function( _, ns, handle ) {
        this.addEventListener("touchmove", handle, { passive: !ns.includes("noPreventDefault") });
    }
};
jQuery.event.special.wheel = {
    setup: function( _, ns, handle ){
        this.addEventListener("wheel", handle, { passive: true });
    }
};
jQuery.event.special.mousewheel = {
    setup: function( _, ns, handle ){
        this.addEventListener("mousewheel", handle, { passive: true });
    }
};
document.addEventListener("DOMContentLoaded", function() {
    $(document).ready(function(){
        $('button.owl-dot').attr('aria-label','Review');
        $('body').append("<script src=\"https://chat.broadly.com/javascript/chat.js\" defer></script>");
        $('div#broadly-widget iframe').delay(500).attr('title','Chat with us');
    });
    window.addEventListener('scroll', function() {
        if (window.scrollY > 50) {
            document.getElementById('top-brand').classList.add('scrolled');
            // add padding top to show content behind navbar
            var navbar_height = document.querySelector('#top-brand').offsetHeight;
            document.body.style.paddingTop = navbar_height + 'px';
        } else {
            document.getElementById('top-brand').classList.remove('scrolled');
            // remove padding top from body
            document.body.style.paddingTop = '0';
        }
    });
});
// start the Stimulus application
